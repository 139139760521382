import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillSetting } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import toastr from "toastr";
import Header from "../../../_components/CommonComponents/Header/Header";
import { Loader, Pagination, Select } from "../../../_components/CommonComponents/index";
import { emailService } from "../../../_services/servicesWithSecondOrigin";
import EventDataListComponent from "./EventDataList";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const EmailTrackingListComponent = forwardRef((props, ref) => {
  const router = useHistory();
  const [dataObj, setDataObj] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderSentMail, setLoaderSentMail] = useState(false);
  const [totalBounce, setTotalBounce] = useState(0);
  const [totalEmails, setTotalEmails] = useState(0);
  const [selectedOption, setSelectedOption] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [isShowSearch, setIsShowSearch] = useState(props?.isShowSearch ?? true);
  const getTodayDate = (daysAgo = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Form related to variables
  const [messageId, setMessageId] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [mailTo, setMailTo] = useState("");
  const [bounce, setBounce] = useState("");
  const [startDateSent, setStartDateSent] = useState(props?.start_datetime || getTodayDate(2000));
  const [endDateSent, setEndDateSent] = useState(props?.end_datetime || getTodayDate());

  // Email setting related variables
  const [showModal, setShowModal] = useState(false);
  const [emailSettingStatus, setEmailSettingStatus] = useState(0);
  const [emailAwsUrl, setEmailAwsUrl] = useState("");
  const [showAwsUrl, setShowAwsUrl] = useState(false);
  const [subscribeUrl, setSubscribeUrl] = useState("");

  // Test email related variables
  const [showTestMailModal, setShowTestMailModal] = useState(false);
  const [testEmail, setTestEmail] = useState("");
  const [testSubject, setTestSubject] = useState("");
  const [testBody, setTestBody] = useState("");
  const [errors, setErrors] = useState({});

  // Event related variables
  const [showEventDataModal, setShowEventDataModal] = useState(false);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    getEmailData(1);
  }, []);

  useImperativeHandle(ref, () => ({
    getEmailData(page = "") {
      getEmailData(page);
    },
    openEmailSettings() {
      openEmailSettings();
    },
  }));

  /**
   * The getEmailData function fetches email tracking data based on specified parameters and updates
   * the state with the retrieved information.
   */
  const getEmailData = async (page = "") => {
    setLoader(true);
    const payload = {
      page: page ? page : currentPage,
      message_id: messageId,
      mail_subject: mailSubject,
      mail_to: mailTo,
      bounce: bounce,
      start_date_sent: props?.start_datetime || startDateSent,
      end_date_sent: props?.end_datetime || endDateSent,
      pageSize: selectedOption,
    };
    const res = await emailService.getEmailTrackList(payload);
    if (res?.status === 200) {
      setDataObj(res?.data?.list);
      setTotalBounce(res?.data?.total_email_bounce);
      setTotalEmails(res?.data?.total_email_sent);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  /**
   * The getEmailSettings function fetches email settings data and updates state variables accordingly.
   */
  const getEmailSettings = async () => {
    setLoader(true);
    const res = await emailService.getEmailSetting();
    if (res?.status === 200) {
      setEmailAwsUrl(res?.data?.aws_url);
      setEmailSettingStatus(res?.data?.companyData?.email_setting);
      if (res?.data?.companyData?.email_setting === "1") {
        setShowAwsUrl(true);
      } else {
        setShowAwsUrl(false);
      }
      setSubscribeUrl(res?.data?.subscribe_url);
      setShowModal(true);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  /**
   * The function `updateEmailSetting` updates the email setting status using data from an API response
   * and displays a success message using toastr.
   * @param e - The `e` parameter in the `updateEmailSetting` function is typically an event object
   * that is passed when the function is called in response to an event, such as a button click or form
   * submission. This event object contains information about the event that occurred, such as the
   * target element or any data
   */
  const updateEmailSetting = async (e) => {
    const payload = {
      email_setting: String(emailSettingStatus),
    };
    const res = await emailService.updateEmailSetting(payload);
    if (res?.status === 200) {
      setEmailSettingStatus(res?.data?.companyData?.email_setting);
      setShowModal(false);
      toastr.success(res.message);
    }
  };

  /* The above code is using the useEffect hook in React to calculate the number of pages needed to
  display a list of emails based on the total number of emails (totalEmails), the selected option
  for number of emails per page (selectedOption), and the data object containing the emails
  (dataObj). */
  useEffect(() => {
    if (dataObj && Array.isArray(dataObj)) {
      let length = totalEmails;
      let pageCount = parseInt(length / selectedOption);
      if (length % selectedOption !== 0) {
        pageCount = pageCount + 1;
      }
      setPageCount(pageCount);
    }
  }, [dataObj, selectedOption, totalEmails]);

  const setPageNumber = (page) => {
    setCurrentPage(page);
    getEmailData(page);
  };

  const onsubmitSearch = (e) => {
    getEmailData(1);
    setCurrentPage(1);
  };

  useEffect(() => {
    getEmailData(1);
  }, [selectedOption]);

  const setSelectedOptionNumber = (e) => {
    setSelectedOption(e.value);
    setCurrentPage(1);
  };

  const openEmailSettings = () => {
    getEmailSettings();
  };

  /**
   * The `validateForm` function in JavaScript validates form fields based on specified rules and
   * returns true if all fields pass validation.
   * @param [key] - The `key` parameter in the `validateForm` function is used to specify which field
   * to validate. If `key` is an empty string (`""`), all fields will be validated. If `key` is a
   * specific field key (e.g., `"testEmail"`), only that field
   * @returns The `validateForm` function is returning a boolean value indicating whether the form
   * validation was successful or not. If there are no errors in the form fields, it will return
   * `true`, indicating that the form is valid. If there are errors in the form fields, it will return
   * `false`, indicating that the form is invalid.
   */
  const validateForm = (key = "") => {
    const newErrors = {};

    const validateField = (fieldKey, value, message, regex = null) => {
      if (!value) {
        newErrors[fieldKey] = message.required;
      } else if (regex && !regex.test(value)) {
        newErrors[fieldKey] = message.invalid;
      }
    };

    const validationRules = {
      testEmail: {
        value: testEmail,
        message: {
          required: "Email is required.",
          invalid: "Invalid email format.",
        },
        regex: /\S+@\S+\.\S+/,
      },
      testSubject: {
        value: testSubject,
        message: {
          required: "Subject is required.",
        },
      },
      testBody: {
        value: testBody,
        message: {
          required: "Body is required.",
        },
      },
    };

    if (key === "") {
      // Validate all fields
      Object.keys(validationRules).forEach((fieldKey) => {
        const { value, message, regex } = validationRules[fieldKey];
        validateField(fieldKey, value, message, regex);
      });
    } else if (validationRules[key]) {
      // Validate a single field
      const { value, message, regex } = validationRules[key];
      validateField(key, value, message, regex);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   * The function `sentTestMail` sends a test email using the provided email service and displays
   * success or error messages accordingly.
   * @param e - In the provided code snippet, the `sentTestMail` function is an asynchronous function
   * that is responsible for sending a test email using the `emailService`. Let's break down the
   * parameters and the flow of the function:
   */
  const sentTestMail = async (e) => {
    if (validateForm()) {
      setLoaderSentMail(true);
      const payload = {
        email: testEmail,
        subject: testSubject,
        body: testBody,
      };

      const res = await emailService.sendTestMail(payload);
      if (res?.status === 200) {
        setTestEmail("");
        setTestSubject("");
        setTestBody("");
        setLoaderSentMail(false);
        toastr.success(res.message);
        setShowTestMailModal(false);
      } else {
        setLoaderSentMail(false);
        toastr.error("Error occured while sending mail");
      }
    } else {
    }
  };

  // Add this new function inside EmailTrackingListComponent
  const getEventData = (type, data) => {
    // You can handle the event data here
    setEventData({ eventType: type, data: data });
    setShowEventDataModal(true);
  };

  const nevigateToSesTrackDetails = () => {
    router.push("/ses-tracking");
  };

  return (
    <>
      <div className={`${isShowSearch ? "container" : ""}`}>
        <Header history={props.history} {...props}></Header>
        <div className="loaderCenter">
          {" "}
          <Loader loader={loader}></Loader>
        </div>
        <div className="seo-monitoring">
          <div hidden={!isShowSearch} className="emailTrackDataSection emailPageTopFilter">
            {/* Heading section */}
            <div className="emailTrackHeading">
              <Row>
                <Col sm={8} md={8} lg={8}>
                  <div className="d-flex">
                    <Button className="backBtn hiddenMob mr-2" onClick={() => props.history.push("/ses")}>
                      <IoIosArrowRoundBack></IoIosArrowRoundBack>
                    </Button>
                    <h6 className="card-title ml-0" style={{marginTop: "7px"}}>SES Tracking Data</h6>
                  </div>
                </Col>
                <Col sm={4} md={4} lg={4} className="email_setting_btn">
                  <div className="d-flex smlBlueIcons">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Manage Email Setting</Tooltip>}>
                      <Button className="iconBtn ml-2 mr-2" onClick={() => openEmailSettings(true)}>
                        {" "}
                        <AiFillSetting></AiFillSetting>
                      </Button>
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            </div>
            {/* End of heading section */}
            {/* Filter section */}
            {/* Here form for search */}
            <Form onSubmit={(e) => onsubmitSearch(e)}>
              <Row>
                <Col sm={6} md={4} lg={4}>
                  <FormGroup>
                    <Label for="messageId">Message ID</Label>
                    <Input
                      id="messageId"
                      name="messageId"
                      placeholder=""
                      type="text"
                      value={messageId}
                      onChange={(e) => {
                        setMessageId(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <FormGroup>
                    <Label for="emailSubject">Email Subject</Label>
                    <Input
                      id="emailSubject"
                      name="emailSubject"
                      placeholder=""
                      type="text"
                      value={mailSubject}
                      onChange={(e) => {
                        setMailSubject(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <FormGroup>
                    <Label for="emailSentTo">Email Sent To</Label>
                    <Input
                      id="emailSentTo"
                      name="emailSentTo"
                      placeholder=""
                      type="text"
                      value={mailTo}
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={6} md={4} lg={4}>
                  <FormGroup>
                    <Label for="bounce">Bounce</Label>
                    <Input
                      id="bounce"
                      name="bounce"
                      placeholder=""
                      type="select"
                      value={bounce}
                      onChange={(e) => {
                        setBounce(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <FormGroup>
                    <Label for="startDateSent">Start Date Sent</Label>
                    <Input
                      id="startDateSent"
                      name="startDateSent"
                      placeholder=""
                      type="date"
                      value={startDateSent}
                      onChange={(e) => {
                        setStartDateSent(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <FormGroup>
                    <Label for="endDateSent">End Date Sent</Label>
                    <Input
                      id="endDateSent"
                      name="endDateSent"
                      placeholder=""
                      type="date"
                      value={endDateSent}
                      onChange={(e) => {
                        setEndDateSent(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <button
                type="button"
                onClick={(e) => {
                  onsubmitSearch(e);
                }}
                className="ml-2 addEditBtn btn btn-primary"
              >
                Search
              </button>
            </Form>
            {/* End of form */}
            {/* End of filter section */}
          </div>
          <div>
            <div className={`${isShowSearch ? "card" : ""}`}>
              <div className={`${isShowSearch ? "card-body mp-0" : ""}`} style={{ position: "relative" }}>
                <div className="emailSectionHead">
                  <p>
                    <span className="subHeadTestMail">Emails Sent:</span> {totalEmails} | <span className="subHeadTestMail">Emails Bounced:</span> {totalBounce}
                    {!isShowSearch && (
                      <Button color="link" onClick={(e) => nevigateToSesTrackDetails()} className="view-details-link float-right mt-0 mb-1">
                        View Details
                      </Button>
                    )}
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className={dataObj ? "co" : "co2"}>
                      {Array.isArray(dataObj) && dataObj.length > 0 && (
                        <tr>
                          <th scope="col">S.No.</th>
                          {/* <th>ID</th> */}
                          <th scope="col">Email Subject</th>
                          <th scope="col">Email Sent To</th>
                          <th scope="col">Sent Date</th>
                          <th scope="col">Open</th>
                          <th scope="col">Click</th>
                          {/* <th>Bounce</th> */}
                          <th scope="col">Bounce</th>
                          <th scope="col">Bounce Type</th>
                          <th scope="col">Bounce Sub Type</th>
                          <th scope="col">Diagnostic Code</th>
                          <th scope="col">Message ID</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {Array.isArray(dataObj) && dataObj.length ? (
                        dataObj?.map((data, index) => {
                          return (
                            <tr key={data?.id}>
                              <td>{index + 1}</td>
                              {/* <td>{data?.id}</td> */}
                              <td>{data?.mail_subject}</td>
                              <td>{data?.mail_to}</td>
                              <td>{data?.date_sent}</td>
                              <td>
                                <span className="cursor-pointer email-event-data" onClick={() => getEventData("open", data)} style={{ cursor: "pointer" }}>
                                  {data?.open}
                                </span>
                              </td>
                              <td>
                                <span className="cursor-pointer email-event-data" onClick={() => getEventData("Click", data)} style={{ cursor: "pointer" }}>
                                  {data?.click}
                                </span>
                              </td>
                              {/* <td>{data?.bounce}</td> */}
                              <td>{data?.bounce == 1 ? "Yes" : "No"}</td>
                              <td>{data?.bounce_type}</td>
                              <td>{data?.bounce_sub_type}</td>
                              <td>{data?.diagnostic_code}</td>
                              <td>{data?.message_id}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">
                            No Record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {Array.isArray(dataObj) && dataObj.length !== 0 && (
                    <div className="paginationSec col-md-12">
                      <div className="totalPage">
                        <Select
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOptionNumber}
                          options={[
                            { label: 10, value: 10 },
                            { label: 30, value: 30 },
                            { label: 50, value: 50 },
                            { label: 70, value: 70 },
                            { label: 100, value: 100 },
                          ]}
                        ></Select>
                      </div>
                      <Pagination currentPage={currentPage} pageCount={pageCount} onClickPage={(page) => setPageNumber(page)}></Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Here create modal for settings */}
        <Modal size="md" isOpen={showModal} toggle={() => {}}>
          <div style={{ height: "100%" }}>
            <ModalBody>
              <ImCross
                style={{ float: "right" }}
                className="close"
                size="10"
                onClick={() => {
                  setShowModal(false);
                }}
              />
              <h6 className="card-title mb-3">Email Setting</h6>

              <Row>
                <Col sm={12} md={12} lg={12}>
                  <Form onSubmit={(e) => updateEmailSetting(e)}>
                    <FormGroup>
                      <Input
                        id="bounce"
                        name="email"
                        placeholder=""
                        type="select"
                        value={emailSettingStatus}
                        onChange={(e) => {
                          setEmailSettingStatus(e.target.value);
                        }}
                      >
                        <option value="0">Disable</option>
                        <option value="1">Enable</option>
                      </Input>
                    </FormGroup>
                    <button
                      type="button"
                      onClick={(e) => {
                        updateEmailSetting(e);
                      }}
                      className="ml-2 addEditBtn emailSettingBtn btn btn-primary"
                    >
                      Save
                    </button>
                  </Form>
                </Col>
              </Row>

              {/* Here url details and test email functionality */}
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <p className="mt-3">
                    <span className="subHeadTestMail">Test Mail:</span>{" "}
                    <button
                      className="testMailLink btn btn-link p-0"
                      onClick={() => {
                        setShowModal(false);
                        setShowTestMailModal(true);
                      }}
                    >
                      Click Here
                    </button>
                  </p>
                </Col>

                {showAwsUrl && (
                  <Col sm={12} md={12} lg={12}>
                    <p>
                      <span className="subHeadTestMail">URL to add in AWS: </span>
                      {emailAwsUrl}
                    </p>
                  </Col>
                )}
              </Row>
              {/* End of Urls */}
            </ModalBody>
          </div>
        </Modal>
        {/* End of modal */}

        {/* Here Modal for Test Mail */}
        <Modal size="md" isOpen={showTestMailModal} toggle={() => {}}>
          <div style={{ height: "100%" }}>
            <div className="loaderCenter">
              {" "}
              <Loader loader={loaderSentMail}></Loader>
            </div>
            <ModalBody>
              <ImCross
                style={{ float: "right" }}
                className="close"
                size="10"
                onClick={() => {
                  setShowTestMailModal(false);
                }}
              />
              <h6 className="card-title mb-3">Test Mail</h6>
              <Form onSubmit={(e) => sentTestMail(e)}>
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <FormGroup>
                      <Label for="testEmail">Email</Label>
                      <Input
                        id="testEmail"
                        name="testEmail"
                        type="email"
                        value={testEmail}
                        onChange={(e) => {
                          setTestEmail(e.target.value);
                          validateForm("testEmail");
                        }}
                      />
                      {errors.testEmail && <div className="text-danger">{errors.testEmail}</div>}
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <FormGroup>
                      <Label for="testSubject">Subject</Label>
                      <Input
                        id="testSubject"
                        name="testSubject"
                        type="text"
                        value={testSubject}
                        onChange={(e) => {
                          setTestSubject(e.target.value);
                          validateForm("testSubject");
                        }}
                      />
                      {errors.testSubject && <div className="text-danger">{errors.testSubject}</div>}
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={12} lg={12}>
                    <FormGroup>
                      <Label for="testBody">Body</Label>
                      <Input
                        id="testBody"
                        name="testBody"
                        type="textarea"
                        value={testBody}
                        onChange={(e) => {
                          setTestBody(e.target.value);
                          validateForm("testBody");
                        }}
                      />
                      {errors.testBody && <div className="text-danger">{errors.testBody}</div>}
                    </FormGroup>
                  </Col>
                </Row>
              </Form>

              <Row className="justify-content-center">
                <Col xs={12}>
                  <button
                    disabled={loaderSentMail}
                    type="button"
                    onClick={sentTestMail}
                    className={`ml-2 addEditBtn emailSettingBtn btn btn-primary ${loaderSentMail ? "loading" : ""}`}
                  >
                    {loaderSentMail ? `Sending...` : `Send Email`}
                  </button>
                </Col>
              </Row>
              {/* Here form regarding  */}
            </ModalBody>
          </div>
        </Modal>
        {/* End of Modal for Test Mail */}

        {/* Here modal for event data  */}
        <Modal size="xl" isOpen={showEventDataModal} toggle={() => {}}>
          <div style={{ height: "100%" }}>
            <ModalBody>
              <ImCross
                style={{ float: "right" }}
                className="close"
                size="10"
                onClick={() => {
                  setShowEventDataModal(false);
                }}
              />
              <EventDataListComponent {...eventData} />
            </ModalBody>
          </div>
        </Modal>
      </div>
    </>
  );
});

export default EmailTrackingListComponent;
