import React, { useState, useEffect } from "react";

import { FaUser } from "react-icons/fa";
import { BiMoon } from "react-icons/bi";
import { CgSun } from "react-icons/cg";

// import { BsFillSunFill, BsMoonFill} from "react-icons/bs";
import { isLogin } from "../../../_helpers/Common/Common";
import g8whitelogo from "../../../assets/img/g8logo_white.png";
import g8logo from "../../../assets/img/g8-logo.png";
import { loginService } from "../../../_services/servicesWithSecondOrigin/Login/login";
import { Link } from "react-router-dom";
import { Profile } from "react-facebook";
import { ProfilePopup } from "./ProfileMenu";
const Header = ({ history, ...props }) => {
  const [authData, setAuthData] = useState("");
  const [active, isActive] = useState(0);
  const [light, isLight] = useState(true);
  useEffect(() => {
    let data = localStorage.getItem("basicAuthData");
    if (data) {
      setAuthData(JSON.parse(data));
    }
  }, [localStorage.getItem("basicAuthData")]);

  useEffect(() => {
    isActive(props.active);
  }, [props.active]);

  useEffect(() => {
    var element = document.body;
    if (localStorage.getItem("themes") === "dark" && !element.classList?.contains("dark")) {
      DarkMode();
    } else if (element.classList?.contains("dark")) {
      localStorage.setItem("themes", "dark");
      isLight(false);
    }
  }, []);

  const DarkMode = async (type) => {
    if (light) {
      localStorage.setItem("themes", "dark");
    } else {
      localStorage.removeItem("themes");
    }
    var element = document.body;
    element.classList.toggle("dark");
    isLight(!light);
    let paths = ["/view-domain-details", "/monitor-urls-list", "/monitor-server/charts", "/monitor-server/chart"];
    if (
      type === 1
      //  && paths.includes(window.location.pathname)
    ) {
      //   window.location.reload(false);
      if (props.callRefresh) {
        props.callRefresh();
      }
    }
  };

  const logout = async () => {
    let res = await loginService.logout();
    if (res && res.status) {
      localStorage.clear();
      history.push("/login");
    }
  };

  return (
    <div className="header_container">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          {/* <a className="navbar-brand" href="#"><img src={logo} alt="Logo" /></a> */}
          <Link className="navbar-brand lightLogo" to="/dashboard">
            <img src={g8logo} alt="Logo" />
          </Link>
          <Link className="navbar-brand darkLogo" to="/dashboard">
            <img src={g8whitelogo} alt="Logo" />
          </Link>

          <label className="switch fixedRightMob">
            <input type="checkbox" onClick={() => DarkMode(1)} />
            {/* {light? <BsFillSunFill className="actionIcon"/>:<BsMoonFill className="actionIcon"/>} */}
            {light ? <BiMoon className="actionIcon" /> : <CgSun className="actionIcon" />}
          </label>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#headerNvBar"
            aria-controls="headerNvBar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <div className="collapse navbar-collapse" id="headerNvBar">
            <ul className="navbar-nav mr-auto">
              {isLogin() && (
                <>
                  <li className="nav-item">
                    <Link className={active === 1 ? "nav-link active" : "nav-link"} href="javascript:void(0)" to="/dashboard">
                      Dashboard <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                            <a className="nav-link" href="javascript:void(0)">About</a>
                        </li> */}
                  <li className="nav-item">
                    <Link className={active === 2 ? "nav-link active" : "nav-link"} href="javascript:void(0)" to="/monitor-servers">
                      Monitor Servers
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className={active === 3 ? "nav-link active" : "nav-link"} href="javascript:void(0)" to="/monitor-urls-list">
                      Monitor Urls
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                            <a className="nav-link" href="javascript:void(0)" onClick={() => history.push('/notification-channel')}>Notification Channel</a>
                        </li> */}

                  <li className="nav-item">
                    <Link className={active === 4 ? "nav-link active" : "nav-link"} href="javascript:void(0)" to="/view-notification-permission">
                      Manage Notifications
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className={active === 5 ? "nav-link active" : "nav-link"} href="javascript:void(0)" to="/email-inboxes">
                      Emails
                    </Link>
                  </li>

                  {/* Here link for SES */}
                  <li className="nav-item">
                    <Link className={active === 6 ? "nav-link active" : "nav-link"} href="javascript:void(0)" to="/ses">
                      SES
                    </Link>
                  </li>
                  {/* End of SES link */}
                </>
              )}
            </ul>
            {/* <ul className="navbar-nav">
                        <li className="nav-item">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Dropdown
                                </button>
                                <div class="dropdown-menu darkLayout" aria-labelledby="dropdownMenuButton">
                                    <Button onClick={() => DarkMode()}>dark mode</Button>
                                </div>
                                </div>
                        </li> 
                    </ul> */}
            <ul className="navbar-nav hiddenMob">
              <li className="nav-item">
                <label className="switch">
                  <input type="checkbox" onClick={() => DarkMode(1)} />
                  {/* {light? <BsFillSunFill className="actionIcon"/>:<BsMoonFill className="actionIcon"/>} */}
                  {light ? <BiMoon className="actionIcon" /> : <CgSun className="actionIcon" />}
                </label>
              </li>
            </ul>
            <ul className="navbar-nav">
              {!isLogin() ? (
                <li className="nav-item">
                  <a className="nav-link logoutBtn" href="javascript:void(0)" onClick={() => history.push("/login")}>
                    login/sign up
                  </a>
                </li>
              ) : (
                <>
                  <li className="nav-item d-flex">
                    <ProfilePopup
                      logout={
                        <a
                          // className="nav-link logoutBtn"
                          href="javascript:void(0)"
                          onClick={() => logout()}
                        >
                          Logout
                        </a>
                      }
                    >
                      <label className="loginUserName">
                        <FaUser /> <span>{authData.first_name + " "}</span>
                      </label>
                    </ProfilePopup>{" "}
                  </li>
                  {/* <li className="nav-item d-flex">
                    <a
                      className="nav-link logoutBtn"
                      href="javascript:void(0)"
                      onClick={() => logout()}
                    >
                      Logout
                    </a>
                  </li> */}
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Header;
