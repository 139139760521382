import React, { useEffect, useRef, useState } from "react";
import { DateTime, Loader } from "../../_components/CommonComponents";
import Header from "../../_components/CommonComponents/Header/Header";
import { AiFillSetting } from "react-icons/ai";
import { Button, Col, Row } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EmailTrackingListComponent from "../Email/Tracking/EmailTrackingList";
import LineStackChart from "../../_components/Charts/LineStackChart";
import toastr from "toastr";
import { emailService } from "../../_services/servicesWithSecondOrigin";
import moment from "moment";
import { MdRemoveRedEye } from "react-icons/md";

function SesStats(props) {
  const emailTrackingListRef = useRef();
  const [loader, setLoader] = useState(false);
  const [sesStats, setSesStats] = useState([]);

  /**
   * Generates the current date and time formatted as 'YYYY-MM-DD HH:mm:ss'.
   *
   * @param {number} daysAgo - The number of days to subtract from the current date.
   *                            Defaults to 0, which returns the current date and time.
   * @returns {string} The formatted date and time string.
   */
  const getTodayDate = (daysAgo = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [start_datetime, setStartDateTime] = useState(getTodayDate(1));
  const [end_datetime, setEndDateTime] = useState(getTodayDate());

  const filterPassedStartDate = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const endDateTime = new Date(moment(end_datetime));
    return currentDate > selectedDate && endDateTime >= selectedDate;
  };

  const filterPassedEndDate = (time) => {
    const currentDate = new Date();
    const startDateTime = new Date(moment(start_datetime));
    const selectedDate = new Date(time);
    return currentDate > selectedDate && startDateTime <= selectedDate;
  };

  const setStartDateTimePicker = (date) => {
    setStartDateTime(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    var a = moment(end_datetime, "YYYY-MM-DD HH:mm:ss");
    var b = moment(moment(date).format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
    let c = a.diff(b, "days");
    if (c > 31) {
      setEndDateTime(b.add(1, "M").add(-1, "D").format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const setEndDateTimePicker = (date) => {
    setEndDateTime(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    var a = moment(moment(date).format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
    var b = moment(start_datetime, "YYYY-MM-DD HH:mm:ss");
    let c = a.diff(b, "days");
    if (c > 31) {
      setStartDateTime(a.add(-1, "M").format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const openEmailSettings = (open) => {
    emailTrackingListRef.current.openEmailSettings(true);
  };

  useEffect(() => {
    getSesStats();
  }, []);

  const getSesStats = async () => {
    const params = {
      start_datetime: start_datetime,
      end_datetime: end_datetime,
    };
    setLoader(true);
    const response = await emailService.getSesStats(params);
    if (response && response?.status === 200) {
      setSesStats(response?.data?.list);
    } else {
      toastr.error("Something went wrong");
    }
    setLoader(false);
  };

  const searchSesData = () => {
    emailTrackingListRef.current.getEmailData(1);
    getSesStats();
  };

  return (
    <>
      <div className="container">
        <Header history={props.history} {...props}></Header>
        <div className="loaderCenter">
          <Loader loader={loader}></Loader>
        </div>
        <div className="serverDetailsSec chartDateTimeFilter">
          <div className="d-flex">
            <div className="select_startdatetime">
              <div className="row align-items-end">
                <div className="col-md-4">
                  <h6 className="card-title mr-2">SES Tracking Data</h6>
                </div>

                <div className="col-md-3">
                  <label>Start Date Time</label>
                  <DateTime className="form-control" dateTime={start_datetime} setDateTime={setStartDateTimePicker} filterPassedDate={filterPassedStartDate} />
                </div>

                <div className="col-md-3">
                  <label>End Date Time</label>
                  <DateTime className="form-control" dateTime={end_datetime} setDateTime={setEndDateTimePicker} filterPassedDate={filterPassedEndDate}></DateTime>
                </div>

                <div className="col-md-2">
                  <div className="d-flex smlBlueIcons">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>View</Tooltip>}>
                      <Button className="iconBtn ml-2 mr-2" onClick={() => searchSesData()}>
                        {" "}
                        <MdRemoveRedEye></MdRemoveRedEye>
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Manage Email Setting</Tooltip>}>
                      <Button className="iconBtn ml-2 mr-2" onClick={() => openEmailSettings(true)}>
                        {" "}
                        <AiFillSetting></AiFillSetting>
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="card">
            <div className="card-body mp-0" style={{ position: "relative" }}>
              {/* Row for charts */}
              <Row>
                <Col md={6}>
                  <div className="card">
                    <div className="card-body">
                      <LineStackChart
                        xAxis={{ type: "category", data: sesStats?.sent?.labels }}
                        yAxis={false}
                        series={[{ data: sesStats?.sent?.values, type: "line", smooth: true, name: "Sent Emails" }]}
                        dataSizeType=""
                        legend={["Sent Emails"]}
                        apiCall={false}
                        tooltip={true}
                        toolbox={true}
                        isLegend={true}
                        mytimezone={"Asia/Kolkata"}
                        user_element={"ses_stats"}
                        uuid={"61c19a724ebde"}
                        unique_id={"61c19a724ebde"}
                        title="Sent Emails"
                        restore={true}
                        dataZoom={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="card">
                    <div className="card-body">
                      <LineStackChart
                        xAxis={{ type: "category", data: sesStats?.bounced?.labels }}
                        yAxis={false}
                        series={[{ data: sesStats?.bounced?.values, type: "line", smooth: true, name: "Bounced Emails" }]}
                        dataSizeType=""
                        legend={["Bounced Emails"]}
                        apiCall={false}
                        tooltip={true}
                        toolbox={true}
                        isLegend={true}
                        mytimezone={"Asia/Kolkata"}
                        user_element={"ses_stats"}
                        uuid={"61c19a724ebda"}
                        unique_id={"61c19a724ebda"}
                        title="Bounced Emails"
                        restore={true}
                        dataZoom={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="card">
                    <div className="card-body">
                      <LineStackChart
                        xAxis={{ type: "category", data: sesStats?.open?.labels }}
                        yAxis={false}
                        series={[{ data: sesStats?.open?.values, type: "line", smooth: true, name: "Open Rate" }]}
                        dataSizeType=""
                        legend={["Open Rate"]}
                        apiCall={false}
                        tooltip={true}
                        toolbox={true}
                        isLegend={true}
                        mytimezone={"Asia/Kolkata"}
                        user_element={"ses_stats"}
                        uuid={"61c19a724ebcc"}
                        unique_id={"61c19a724ebcc"}
                        title="Open Rate"
                        restore={true}
                        dataZoom={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="card">
                    <div className="card-body">
                      <LineStackChart
                        xAxis={{ type: "category", data: sesStats?.click?.labels }}
                        yAxis={false}
                        series={[{ data: sesStats?.click?.values, type: "line", smooth: true, name: "Click Rate" }]}
                        dataSizeType=""
                        legend={["Click Rate"]}
                        apiCall={false}
                        tooltip={true}
                        toolbox={true}
                        isLegend={true}
                        mytimezone={"Asia/Kolkata"}
                        user_element={"ses_stats"}
                        uuid={"61c19a724edddd"}
                        unique_id={"61c19a724dddd"}
                        title="Click Rate"
                        restore={true}
                        dataZoom={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {/* Row for tabular data */}
              <Row>
                <Col md={12}>
                  <div className="card">
                    <div className="card-body">
                      <EmailTrackingListComponent ref={emailTrackingListRef} isShowSearch={false} start_datetime={start_datetime} end_datetime={end_datetime} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SesStats;
