import React, { useState, useEffect } from 'react';
import { monitorServersServices } from '../../../_services/servicesWithSecondOrigin/index'

const ServerDetailss = (props) => {
    const [serverDetails, setsServerDetails] = useState("")
    useEffect(() => {
        if (props.uuid) {
            serverDetailss()
        }
    }, [props.uuid])

    const serverDetailss = async () => {
        var d = new Date();
        var n = d.getTimezoneOffset();
        let response = await monitorServersServices.monitorServerChartDetails({ "uuid": props.uuid, timediff: n })
        if (response && response.data) {
            setsServerDetails(response.data)
        }
    }
    return <div className='row'>
        <div className="col" ><h5>Public Ip</h5>: {serverDetails && serverDetails.Public_ip}</div>
        <div className="col"><h5>Private Ip:</h5> {serverDetails && serverDetails.private_ip}</div>
        <div className="col"><h5>Mac Address:</h5> {serverDetails && serverDetails.mac_address}</div>
        <div className="col"><h5>Last Login:</h5> {serverDetails && serverDetails.get_last_login}</div>
        <div className="col"><h5>Last Reboot Time:</h5> {serverDetails && serverDetails.last_reboot_time}</div>
        <div className="col"><h5>Machine Timezone:</h5> {serverDetails && serverDetails.machine_timezone}</div>
    </div>
}

export default ServerDetailss