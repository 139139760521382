import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import { createBrowserHistory } from "history";
import toastr from "toastr";
import "./App.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/style.css";
import "toastr/build/toastr.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import ConnectToCloudFlare from "./Pages/CloudFlare/ConnectToCloudFlare";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/SignUp/SignUp";
import VerifyEmail from "./Pages/SignUp/VerifyEmail";

import SeoMonitoring from "./Pages/SeoMonitoring/SeoMonitoring";
import SocialMedia from "./Pages/SocialMedia/SocialMedia";
import MonitorULRs from "./Pages/MonitorULRs/MonitorULRs";
import WrongUrl from "./Pages/404/404";
import Dashboard from "./Pages/Dashboard/Dashboard";
import UpdateDomain from "./Pages/Domain/UpdateDomain";
import ViewDomainDetails from "./Pages/Domain/ViewDomainDetails";

import MonitorServers from "./Pages/MonitorServers/MonitorServers";

import BackUpFolderChange from "./Pages/BackUpFolderChange/BackUpFolderChange";
import DisallowedFiles from "./Pages/MonitorServers/DisallowedFiles";

import AddServer from "./Pages/MonitorServers/AddServer";
import ViewServer from "./Pages/MonitorServers/ViewServer";
import ServerHistory from "./Pages/ServerHistory/ServerHistory";
import NotificationChannel from "./Pages/NotificationChannel/NotificationChannel";
import AddNotificationChannel from "./Pages/NotificationChannel/AddNotificationChannel";
import ViewNotificationChannel from "./Pages/NotificationChannel/ViewNotificationChannel";

import MonitorServerIndividualChartTest from "./Pages//MonitorServereCharts/MonitorServerIndividualChartTest";

import MonitorServereSetting from "./Pages/MonitorServerSetting/MonitorServereSetting";
import MonitorServereCharts from "./Pages/MonitorServereCharts/MonitorServereCharts";
import MonitorSettingIndividualChart from "./Pages/MonitorServereCharts/MonitorSettingIndividualChart";
import MonitorServerCron from "./Pages/MonitorServereCharts/MonitorServerCron";
import MonitorServerIndividualChartWithNoTime from "./Pages/MonitorServereCharts/MonitorServerIndividualChartWithNoTime";
import MonitorServerIndividualApachheChart from "./Pages/MonitorServereCharts/MonitorServerIndividualApachheChart";
import MonitorUrlsList from "./Pages/MonitorULRs/MonitorUrlsList";
import NotificationSetting from "./Pages/NotificationSetting/NotificationSetting";

import Email from "./Pages/Email/Email";
import EmailTrackingList from "./Pages/Email/Tracking/EmailTrackingList";
import SesStats from "./Pages/Ses/SesStats";
import EmailInboxs from "./Pages/Email/EmailInboxs";

import { URL_CONFIG } from "./_constants/Config/URL_CONFIG";
import CreditsDetails from "./Pages/CreditsDetails/CreditsDetails";
import ApiMonitorForm from "./Pages/Postman/ApiMonitorForm";
import { ApiMonitorListing } from "./Pages/Postman/ApiMonitorListing";
import MonitorUrlsApis from "./Pages/MonitorUrlsApis/MonitorUrlsApis";

toastr.options = {
  positionClass: "toast-bottom-right",
};

function App() {
  const history = createBrowserHistory();
  return (
    <Router history={history} basename={URL_CONFIG.BASE_URL}>
      <div className="App">
        <Switch>
          <PrivateRoute exact path="/seo-monitoring" component={SeoMonitoring} />
          <PrivateRoute exact path="/update-seo-monitoring" component={SeoMonitoring} />
          <PrivateRoute exact path="/social-media" component={SocialMedia} />
          <PrivateRoute exact path="/update-social-media" component={SocialMedia} />
          <PrivateRoute exact path="/monitor-urls" component={MonitorULRs} />
          <PrivateRoute exact path="/update-monitor-urls" component={MonitorULRs} />

          {/* <PrivateRoute
            exact
            path="/monitor-urls-list"
            component={MonitorUrlsList}
            active={3}
          /> */}

          <PrivateRoute exact path="/dashboard" component={Dashboard} active={1} />
          <PrivateRoute exact path="/update-domain" component={UpdateDomain} />
          <PrivateRoute exact path="/view-domain-details" component={ViewDomainDetails} active={1} />
          <PrivateRoute exact path="/monitor-servers" component={MonitorServers} active={2} />
          <PrivateRoute exact path="/monitor-servers/update-server" component={AddServer} active={2} />
          <PrivateRoute exact path="/monitor-servers/view" component={ViewServer} />
          <PrivateRoute exact path="/monitor-server/server-setting" component={MonitorServereSetting} active={2} />
          <PrivateRoute exact path="/monitor-server/charts" component={MonitorServereCharts} active={2} />
          <PrivateRoute exact path="/monitor-server/history" component={ServerHistory} />
          <PrivateRoute exact path="/monitor-server/chart" component={MonitorSettingIndividualChart} active={2} />
          <PrivateRoute exact path="/monitor-server/cron" component={MonitorServerCron} active={2} />
          {/* for UI  */}
          <PrivateRoute exact path="/monitor-server/testchart" component={MonitorServerIndividualChartTest} active={2} />
          <PrivateRoute exact path="/monitor-server/apache-chart" component={MonitorServerIndividualApachheChart} active={2} />
          <PrivateRoute exact path="/monitor-server/mysql-charts" component={MonitorServerIndividualChartWithNoTime} active={2} />

          <PrivateRoute exact path="/monitor-servers/backup-folder-change" component={BackUpFolderChange} active={2} />
          <PrivateRoute exact path="/monitor-servers/disallowed-files" component={DisallowedFiles} active={2} />

          <PrivateRoute exact path="/notification-channel" component={NotificationChannel} active={4} />
          <PrivateRoute exact path="/add-notification-channel" component={AddNotificationChannel} active={4} />
          <PrivateRoute exact path="/update-notification-channel" component={AddNotificationChannel} active={4} />
          <PrivateRoute exact path="/view-notification-channel" component={ViewNotificationChannel} active={4} />
          <PrivateRoute exact path="/view-notification-permission" component={NotificationSetting} active={4} />
          <PrivateRoute exact path="/email-listing" component={Email} active={5} />
          <PrivateRoute exact path="/email-inboxes" component={EmailInboxs} active={5} />
          {/* Added route for Email Tracking data */}
          <PrivateRoute exact path="/ses" component={SesStats} active={6} />
          <PrivateRoute exact path="/ses-tracking" component={EmailTrackingList} active={6} />
          {/* End of route for Email Tracking data */}
          <PrivateRoute restricted={true} exact component={CreditsDetails} path="/credit-details" />

          <PrivateRoute restricted={true} exact component={ApiMonitorForm} path="/api-monitor-form" />
          <PrivateRoute
            restricted={true}
            exact
            component={MonitorUrlsApis}
            // path="/monitoring"
            path="/monitor-urls-list"
            active={3}
          />

          <PrivateRoute restricted={true} exact component={ApiMonitorListing} path="/api-monitor-list" />

          <Route exact path="/" component={Dashboard} />
          <Route exact path="/connect-to-cloudflare" component={ConnectToCloudFlare} />
          <PublicRoute restricted={true} exact component={Login} path="/login" />
          <PublicRoute restricted={true} exact component={SignUp} path="/signup" />
          <PublicRoute restricted={true} exact component={VerifyEmail} path="/verify-email/:id/:token" />
          {/* <PublicRoute exact restricted={true} component={Login} path="/login" /> */}
          <Route exact path="/404" component={WrongUrl} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
