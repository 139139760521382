import React, { useEffect, useState } from "react";
import { emailService } from "../../../_services/servicesWithSecondOrigin";
import { Loader, Pagination, Select } from "../../../_components/CommonComponents";

function EventDataListComponent(props) {
  const { eventType, data } = props;
  const [loader, setLoading] = useState(true);
  const [dataObj, setDataObj] = useState([]);

  // Here pagination related variables will be declared
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedOption, setSelectedOption] = useState(10);
  console.log("selectedOption", selectedOption);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    getEventData(data, eventType);
  }, [data, eventType]);

  useEffect(() => {
    if (dataObj && Array.isArray(dataObj)) {
      let length = totalRecords;
      let pageCount = parseInt(length / selectedOption);
      if (length % selectedOption !== 0) {
        pageCount = pageCount + 1;
      }
      setPageCount(pageCount);
    }
  }, [dataObj, selectedOption, totalRecords]);

  const getEventData = async (data, eventType, page = "") => {
    setLoading(true);
    const payload = {
      event_type: eventType,
      id: data?.message_id,
      page: page ? page : currentPage,
      page_size: selectedOption,
    };
    const response = await emailService.getEventData(payload);
    if (response?.status === 200) {
      setDataObj(response?.data?.list);
      setTotalRecords(response?.data?.totalCount);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEventData(data, eventType, 1);
  }, [selectedOption]);

  const setSelectedOptionNumber = (e) => {
    setSelectedOption(e.value);
    setCurrentPage(1);
  };

  const setPageNumber = (page) => {
    setCurrentPage(page);
    getEventData(data, eventType, page);
  };

  return (
    <>
      <div className="eventDataSection">
        <div className="loaderCenter">
          {" "}
          <Loader loader={loader}></Loader>
        </div>
        <div className="seo-monitoring">
          <div className="emailTrackEventData">
            <h6 className="card-title mb-0 mr-2">Email Event Data</h6>
          </div>

          <div className="card">
            <div className="card-body mp-0" style={{ position: "relative" }}>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className={dataObj ? "co" : "co2"}>
                    {Array.isArray(dataObj) && dataObj.length > 0 && (
                      <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Mail Subject</th>
                        <th scope="col">Date Sent</th>
                        <th scope="col">Event</th>
                        <th scope="col">Open Time Stamp</th>
                        <th scope="col">User Agent</th>
                        <th scope="col">Ip Address</th>
                        <th scope="col">Sending Account ID</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {Array.isArray(dataObj) && dataObj.length > 0 ? (
                      dataObj.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.mail_subject}</td>
                          <td>{item?.date_sent}</td>
                          <td>{item?.event_type}</td>
                          <td>{item?.open_time_stamp}</td>
                          <td>{item?.user_agent}</td>
                          <td>{item?.ip_address}</td>
                          <td>{item?.sending_account_id}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No Record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {Array.isArray(dataObj) && dataObj.length !== 0 && (
                  <div className="paginationSec col-md-12">
                    <div className="totalPage">
                      <Select
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOptionNumber}
                        options={[
                          { label: 10, value: 10 },
                          { label: 30, value: 30 },
                          { label: 50, value: 50 },
                          { label: 70, value: 70 },
                          { label: 100, value: 100 },
                        ]}
                      ></Select>
                    </div>
                    <Pagination currentPage={currentPage} pageCount={pageCount} onClickPage={(page) => setPageNumber(page)}></Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Here event data will be shown in table format */}
        </div>
      </div>
    </>
  );
}

export default EventDataListComponent;
